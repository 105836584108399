import {USER_INITIAL_STATE} from "./user.state";
import {USER_ACTIONS_TYPES} from "./user.types";

export const userReducer =(state = USER_INITIAL_STATE, action) => {
    const {type, payload } = action;

    switch (type){
        case USER_ACTIONS_TYPES.UPD_USER :
            let nuser = Object.assign({}, state.user);
            nuser[payload.name] = payload.value;
            return {
                state,
                user: nuser
            };
        case USER_ACTIONS_TYPES.LOGIN_USER:
            return {
                ...state,
                user : payload
            }
        case USER_ACTIONS_TYPES.LOGOUT_USER:
            localStorage.clear();
            return {
                ...state,
                user : {}
            }
        case USER_ACTIONS_TYPES.GET_API_KEY_USER:
            let user = Object.assign({}, state.user);
            user.securityAccesses = payload;
            return {
                state,
                user: user
            };

        default:
            return state;
    }
}
