import * as React from 'react';
import {Component, useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import SetupForm from "../components/stripe/addCard";
import {loadStripe} from '@stripe/stripe-js';
import {useDispatch, useSelector} from "react-redux";
import {selectorLoggedUser} from "../store/user/user.selector";
import {useNavigate} from "react-router";
import {loginUserAction} from "../store/user/user.actions";
const stripePromise = loadStripe('pk_live_51IHaDdGkZgdPCt2VZP5IBU11NHkIahGoQvih1TBDtBimvUFQZ0ElDM1lS4sD4xLJeaxFLSFsyc6FRzKofybaKksD00BXDzWNeS');


function PayPage()  {
    const state =useSelector(selectorLoggedUser);
    //const clientSecret = this.state.clientSecret;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let options = {
        // passing the client secret obtained in step 3
        clientSecret: state.clientSecret,//'{{CLIENT_SECRET}}',
        // Fully customizable with appearance API.
        appearance: {
            theme: 'night',
            labels: 'floating'
        },
    };

    return (
            <div>
                <Elements stripe={stripePromise} options={ options}>
                      <SetupForm />
                </Elements>
            </div>
        );

}

export default PayPage
