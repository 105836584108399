import * as React from 'react';

import {Component} from "react";
import SignUp from "./SignUp";



class LogPage extends Component {
    render() {
        return (
            <div>
                <SignUp></SignUp>
            </div>
        );
    }
}

export default LogPage
