import * as React from 'react';

import {Component} from "react";
import LogView from "../components/LogView";


class LogPage extends Component {


    render() {
        return (
            <div>
                <LogView></LogView>
            </div>
        );
    }
}

export default LogPage
