import * as React from 'react';

import {Component} from "react";
import InvoiceList from "../components/InvoiceList";




class InvoicePage extends Component {
    render() {
        return (
            <div>
                <InvoiceList/>
            </div>
        );
    }
}

export default InvoicePage
