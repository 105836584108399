import {useSelector} from "react-redux";
import {selectorLoggedUser} from "../../store/user/user.selector";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, FormControl,
    Grid, InputLabel, MenuItem, Select,
    Typography
} from "@mui/material";
import * as React from "react";
import { useState} from "react";

import Button from "@mui/material/Button";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextField from "@mui/material/TextField";
import axios from "axios";
import {DataGrid} from "@mui/x-data-grid";

export const CoponAdminPage = () => {
    const state =useSelector(selectorLoggedUser);
    const [apiKey, setApiKey] = useState({
        apiKeyName : '',
        onAdd : false,
        onDelete : false,
        erroreMsg : ''
    });
    const [copons, setCopons] = useState([]);
    const [copon, setCopon] = useState({
        name : '',
        maxUsage : 1,
        priceFee : 0.00,
        maxToken : 1,
        maxLogsMonth: 250,
        expired : new Date()
    });
    const [open, setOpen] = useState(false);
    const [openErr, setOpenErr] = useState(false);
    const [token, setToken] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleErrOpen = (msg) => {
        setOpenErr(true);
    };

    const handleErrClose = () => {
        setOpenErr(false);
    };


    const handleChange = (event) => {
        setToken(event.target.value);
    };

    const addError = (event) => {
        //let msg = 'err';
        event.preventDefault();
        //console.log('OPERN ERR');
        handleErrOpen(apiKey.erroreMsg);
    }

    const addSubmit = (event) => {
        event.preventDefault();
        handleClickOpen();
        setApiKey({...apiKey, onAdd : true});
    }



    const ConfirmAdd = (event) => {
        setOpen(false);
        axios.post('https://log.apserial.it/admin/copon', copon, {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
            if(res.status === 201 ) {
                Reload();
            }
            else  {
                setApiKey({...apiKey, erroreMsg :'ERROR GENERIC'} );
                setOpenErr(true);

            }
        }).catch((err) =>{
            setApiKey({...apiKey, erroreMsg : err.response.data.error} );
            setOpenErr(true);
            //console.log(err.response.data.error);
        })
    }



    const Reload = (event) => {
        axios.get('https://log.apserial.it/admin/copon', {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
            if(res.status === 200 ) {
                setCopons(res.data.value);
            }
            else {
                setApiKey({...apiKey, erroreMsg :'ERROR GENERIC'} );
                setOpenErr(true);
            }
        }).catch((err) =>{
            setApiKey({...apiKey, erroreMsg : err.response.data.error} );
            setOpenErr(true);
        })
    }


    const columns = [
        { field: 'id', headerName: 'ID', width: 10 },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            editable: false,
        },
        {
            field: 'maxUsage',
            headerName: 'maxUsage',
            width: 100,
            editable: false,
        },
        {
            field: 'maxToken',
            headerName: 'maxToken',
            width: 100,
            editable: false,
        },
        {
            field: 'priceFee',
            headerName: 'priceFee',
            width: 100,
            editable: false,
        },
        {
            field: 'maxLogsMonth',
            headerName: 'maxLogsMonth',
            width: 100,
            editable: false,
        },
        {
            field: 'active',
            headerName: 'Active',
            type: 'bool',
            width: 100,
            editable: false,
        },
        {
            field: 'expired',
            headerName: 'expired',
            type: "date",
            width: 100,
            editable: false,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            type: "date",
            sortable: false,
            width: 200,
        },
    ];





    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={12} item>
                        <Card>
                            <CardHeader title={`Copon Manage`}  />
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    Action
                                </Typography>
                                <Button variant="contained" color="success" endIcon={<AddCircleIcon />} size="large" sx={{ m: 2 }} onClick={ addSubmit}>
                                    Add
                                </Button>
                                <Button variant="contained" color="info" endIcon={<RefreshIcon />} size="large" sx={{ m: 2 }} onClick={Reload} >
                                    Refresh
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} item>


                        <Box sx={{ display: 'flex' }}>
                            <CssBaseline />
                            <Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid xs={12} item>
                                    <Card>
                                        <CardHeader title={`Copon`}  />
                                    </Card>
                                </Grid>
                                <Grid xs={12} item>
                                    <Card>
                                        <Box sx={{ height: 400, width: '100%' }}>
                                            <DataGrid
                                                //onRowClick={handleRowClick} {...state.securityAccesses}
                                                getRowId={(row) => row.id}
                                                rows={copons ? copons : []}
                                                columns={columns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                disableSelectionOnClick
                                                experimentalFeatures={{ newEditingApi: true }}
                                            />
                                        </Box>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>




                    </Grid>
                </Grid>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Copon</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Set Name for Your Copon
                    </DialogContentText>
                    <TextField
                        onChange={(event) => {
                            const value = event.target.value;
                            setCopon({...copon, name : value});
                        }}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Copon Name"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        onChange={(event) => {
                            const value = event.target.value;
                            setCopon({...copon, maxUsage : value});
                        }}
                        autoFocus
                        margin="dense"
                        id="maxUsage"
                        label="Max Use"
                        type="number"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        onChange={(event) => {
                            const value = event.target.value;
                            setCopon({...copon, priceFee : value});
                        }}
                        autoFocus
                        margin="dense"
                        id="priceFee"
                        label="price Fee"
                        type="number"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        onChange={(event) => {
                            const value = event.target.value;
                            setCopon({...copon, maxToken : value});
                        }}
                        autoFocus
                        margin="dense"
                        id="maxToken"
                        label="Max Token"
                        type="number"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        onChange={(event) => {
                            const value = event.target.value;
                            setCopon({...copon, maxLogsMonth : value});
                        }}
                        autoFocus
                        margin="dense"
                        id="maxLogsMonth"
                        label="Max LogsMonth"
                        type="number"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        onChange={(event) => {
                            const value = event.target.value;
                            setCopon({...copon, expired : value});
                        }}
                        autoFocus
                        margin="dense"
                        id="expired"
                        label="expired"
                        type="date"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={ConfirmAdd}>Create</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openErr}
                onClose={handleErrClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ERROR
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        { apiKey.erroreMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleErrClose} autoFocus>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}
