import {USER_ACTIONS_TYPES} from "./user.types";


export const createAction = (type, payload) => ({ type, payload});

export const loginUserAction = (user) => createAction(USER_ACTIONS_TYPES.LOGIN_USER, user);

export const logoutUserAction = () => createAction(USER_ACTIONS_TYPES.LOGOUT_USER, 'user');

export const apiKeyUserActionGet = (apikey) => createAction(USER_ACTIONS_TYPES.GET_API_KEY_USER, apikey );

export const updDateUser = (field) => createAction(USER_ACTIONS_TYPES.UPD_USER, field);
