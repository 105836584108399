import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import DescriptionIcon from '@mui/icons-material/Description';
import {Link} from "react-router-dom";


export const mainListItems = (
  <React.Fragment>
    <ListItemButton  component={Link} to="/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Logs Dashboard" />
    </ListItemButton>
    <ListItemButton component={Link} to="/invoice">
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Invoiced" />
    </ListItemButton>
    <ListItemButton component={Link} to="/user">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="User Details" />
    </ListItemButton>
    <ListItemButton component={Link} to="/apikey">
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Token" />
    </ListItemButton>
      <ListItemButton component={Link} to="/doc">
          <ListItemIcon>
              <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Documentation" />
      </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
       Admin
    </ListSubheader>
    <ListItemButton component={Link} to="/admin/users">
      <ListItemIcon>
        <AccountCircleIcon />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItemButton>
    <ListItemButton component={Link} to="/admin/change">
      <ListItemIcon>
        <CurrencyExchangeIcon />
      </ListItemIcon>
      <ListItemText primary="ChangePlan" />
    </ListItemButton>
      <ListItemButton component={Link} to="/admin/copon">
          <ListItemIcon>
              <CardGiftcardIcon />
          </ListItemIcon>
          <ListItemText primary="Copon" />
      </ListItemButton>
  </React.Fragment>
);
