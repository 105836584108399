import * as React from 'react';

import {Component} from "react";
import SignIn from "./SignIn";


class LoginPage extends Component {
    render() {
        return (
            <div>
            <SignIn></SignIn>
            </div>
        );
    }
}

export default LoginPage
