export const USER_ACTIONS_TYPES = {
     LOGIN_USER : 'LOGIN_USER',
    LOGOUT_USER : 'LOGOUT_USER',
    UPD_USER : 'UPD_USER',
    GET_API_KEY_USER : 'GET_API_KEY_USER',
    GET_COUNT_USER : 'GET_COUNT_USER',
    GET_LIST_REQ_USER : 'GET_LIST_REQ_USER',
    GET_ADMIN_USER_USER : 'GET_ADMIN_USER',
    ADD_API_KEY_USER : 'ADD_API_KEY_USER',

}
