import {useDispatch, useSelector} from "react-redux";
import {selectorLoggedUser} from "../store/user/user.selector";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Grid

} from "@mui/material";
import * as React from "react";
import { useState} from "react";

import Button from "@mui/material/Button";
import AddCircleIcon from '@mui/icons-material/AddCircle';

import TextField from "@mui/material/TextField";
import axios from "axios";

import {loginUserAction} from "../store/user/user.actions";
import {useNavigate} from "react-router";

export const Copon = () => {
    const state =useSelector(selectorLoggedUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [apiKey, setApiKey] = useState({
        apiKeyName : '',
        onAdd : false,
        onDelete : false,
        erroreMsg : ''
    });
    const [copon, setCopon] = useState({
        name : '',
    });
    const [open, setOpen] = useState(false);
    const [openErr, setOpenErr] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleErrClose = () => {
        setOpenErr(false);
    };


    const addSubmit = (event) => {
        event.preventDefault();
        handleClickOpen();
        setApiKey({...apiKey, onAdd : true});
    }


    const ConfirmAdd = (event) => {
        setOpen(false);
        axios.post('https://log.apserial.it/user/copon', copon, {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
            if(res.status === 200 ) {
                axios.get('https://log.apserial.it/user/loginMe', {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
                    if(res.status === 200 ) {
                        let user = res.data.user;
                        user.token =  state.token;
                        localStorage.setItem('user', JSON.stringify(user));
                        dispatch(loginUserAction(user));
                        navigate('/user');
                    }
                });
            }
            else  {
                setApiKey({...apiKey, erroreMsg :'ERROR GENERIC'} );
                setOpenErr(true);

            }
        }).catch((err) =>{
            setApiKey({...apiKey, erroreMsg : err.response.data.error} );
            setOpenErr(true);
        })
    }

    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={12} item>
                        <Card>
                            <CardHeader title={`You have Copon?`}  />
                            <CardContent>
                                {/*<Typography gutterBottom variant="h6" component="div">*/}
                                {/*    Action*/}
                                {/*</Typography>*/}
                                <Button variant="contained" color="success" endIcon={<AddCircleIcon />} size="large" sx={{ m: 2 }} onClick={ addSubmit}>
                                    Add Copon
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Copon</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Set Name for Copon
                    </DialogContentText>
                    <TextField
                        onChange={(event) => {
                            const value = event.target.value;
                            setCopon({...copon, name : value});
                        }}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Copon Name"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={ConfirmAdd}>Add</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openErr}
                onClose={handleErrClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ERROR
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        { apiKey.erroreMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleErrClose} autoFocus>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}
