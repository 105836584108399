import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useEffect, useState} from "react";
import {loginUserAction, updDateUser} from "../store/user/user.actions";
import {useDispatch, useSelector} from "react-redux";
import {selectorLoggedUser} from "../store/user/user.selector";
import {useNavigate} from "react-router";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import axios from "axios";
import TextField from "@mui/material/TextField";
import {Copon} from "./addCopon";

function Fee() {

    const state =useSelector(selectorLoggedUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [plan, setPlan] = useState('');
    const [newplan, setNewPlan] = useState('');
    const [openErr, setOpenErr] = useState(false);
    const [err, setErr] = useState('');
    const [openUPD, setOpenUPD] = useState(false);
    const [openDelUPD, setOpenDelUPD] = useState(false);
    useEffect(() => {
        if(state) {
            setPlan(state.plan);
        }
    })


    const handleErrClose = () => {
        setOpenErr(false);
    };

    const handleUPDClose = () => {
        setOpenUPD(false);
    };

    const handleDelUPDClose = () => {
        setOpenDelUPD(false);
    };

    const confirmNewPlan = (event) => {
        event.preventDefault();

        let date = {
            name : newplan,
        }

        axios.patch(`https://log.apserial.it/user/sub`, date, {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
            if(res.status === 200 ) {
                axios.get('https://log.apserial.it/user/loginMe', {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
                    if(res.status === 200 ) {
                        let user = res.data.user;
                        user.token =  state.token;
                        localStorage.setItem('user', JSON.stringify(user));
                        dispatch(loginUserAction(user));
                        navigate('/user');
                    }
                });
            }
        }).catch((err) =>{
            setErr(err.response.data.message);
            setOpenErr(true);
            ////console.log(err.response.data.error);
        })

    }


    const confirmDelPlan = (event) => {
        event.preventDefault();
        axios.delete(`https://log.apserial.it/user/sub`,  {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
            if(res.status === 200 ) {
                axios.get('https://log.apserial.it/user/loginMe', {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
                    if(res.status === 200 ) {
                        let user = res.data.user;
                        user.token =  state.token;
                        localStorage.setItem('user', JSON.stringify(user));
                        dispatch(loginUserAction(user));
                        navigate('/user');
                    }
                });
            }
        }).catch((err) =>{
            setErr(err.response.data.message);
            setOpenErr(true);
            ////console.log(err.response.data.error);
        })

    }



    const addDevPlan = (event) => {
        event.preventDefault();
        setNewPlan('DEVELOPER');
        if(!state.addebitFee) {
            axios.get(`https://log.apserial.it/user/secret`, {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
                if(res.status === 200 ) {
                    let rw = {
                        name : 'clientSecret',
                        value : res.data.value.client_secret
                    }
                    localStorage.setItem('client', res.data.value.client_secret );
                    dispatch(updDateUser(rw));
                    navigate('/user/payments');
                    //Reload();
                }
            }).catch((err) =>{
                setErr(err.response.data.error);
                setOpenErr(true);
                ////console.log(err.response.data.error);
            })
        }else  {
            setOpenUPD(true);
        }

        //console.log('CLICK');
    }


    const addStartUp = (event) => {
        event.preventDefault();
        setNewPlan('STARTUP');
        if(!state.addebitFee) {
            axios.get(`https://log.apserial.it/user/secret`, {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
                if(res.status === 200 ) {
                    let rw = {
                        name : 'clientSecret',
                        value : res.data.value.client_secret
                    }
                    localStorage.setItem('client', res.data.value.client_secret );
                    dispatch(updDateUser(rw));
                    navigate('/user/payments');
                    //Reload();
                }
            }).catch((err) =>{
                setErr(err.response.data.error);
                setOpenErr(true);
                //console.log(err.response.data.error);
            })
        }else  {
            setOpenUPD(true);
        }

        //console.log('CLICK');
    }


    const addBussiness = (event) => {
        event.preventDefault();
        setNewPlan('BUSINESS');
        if(!state.addebitFee) {
            axios.get(`https://log.apserial.it/user/secret`, {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
                if(res.status === 200 ) {
                    let rw = {
                        name : 'clientSecret',
                        value : res.data.value.client_secret
                    }
                    localStorage.setItem('client', res.data.value.client_secret );
                    dispatch(updDateUser(rw));
                    navigate('/user/payments');
                    //Reload();
                }
            }).catch((err) =>{
                setErr(err.response.data.error);
                setOpenErr(true);
                //console.log(err.response.data.error);
            })
        }else  {
            setOpenUPD(true);
        }

        //console.log('CLICK');
    }

    const addFREE = (event) => {
        event.preventDefault();

        if(state.plan !== 'FREE') {
            setNewPlan('FREE');
            setOpenDelUPD(true);
        }
        else {
            setErr('You have a Free Plan');
            setOpenErr(true);
        }

    }






    const tiers = [
        {
            title: 'FREE',
            price: '0',
            description: [
                '1 Token included',
                '250 Logs/30dd for token',
            ],
            buttonText: state.plan === 'FREE' ? 'Sign up for free' : 'Deactivate' ,
            buttonVariant: 'outlined',
            function : addFREE,
        },
        {
            title: 'DEVELOPER',
            subheader: 'Most popular',
            price: '5',
            description: [
                '5 Token included',
                '1000 Logs/30dd for token',
            ],
            buttonText: 'Get started',
            buttonVariant: 'contained',
            function : addDevPlan,
        },
        {
            title: 'STARTUP',
            price: '15',
            description: [
                '25 Token included',
                '5000 Logs/30dd for token',
            ],
            buttonText: 'Get started',
            buttonVariant: 'outlined',
            function : addStartUp,
        },
        {
            title: 'BUSINESS',
            price: '75',
            description: [
                '125 Token included',
                '25000 Logs/30dd for token',
            ],
            buttonText: 'Get started',
            buttonVariant: 'outlined',
            function : addBussiness,
        },
    ];




    return (
        <div>
            <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Pricing
                </Typography>
                <Typography variant="h5" align="center" color="text.secondary" component="p">
                    These are our Simple subscription plans, tailored to every level of requirement
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main">
                <Grid container spacing={4} alignItems="flex-end">
                    {tiers.map((tier) => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid
                            item
                            key={tier.title}
                            xs={12}
                            sm={tier.title === 'DEVELOPER' ? 12 : 6}
                            md={3}
                        >
                            <Card>
                                <CardHeader
                                    title={tier.title}
                                    subheader={tier.subheader}
                                    titleTypographyProps={{ align: 'center' }}
                                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[800],
                                    }}
                                />
                                <CardContent>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'baseline',
                                            mb: 2,
                                        }}
                                    >
                                        <Typography component="h2" variant="h3" color="text.primary">
                                            €{tier.price}
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary">
                                            /30dd
                                        </Typography>
                                    </Box>
                                    <ul>
                                        {tier.description.map((line) => (
                                            <Typography
                                                component="li"
                                                variant="subtitle1"
                                                align="center"
                                                key={line}
                                            >
                                                {line}
                                            </Typography>
                                        ))}
                                    </ul>
                                </CardContent>
                                <CardActions>

                                    {
                                        plan !== 'FREE' && tier.title === 'FREE' &&
                                        <Button fullWidth variant={tier.buttonVariant}  onClick={tier.function} color="error">
                                           Deactivate Plan
                                        </Button>
                                    }
                                    {
                                        plan === tier.title && tier.title === 'FREE' &&
                                        <Button fullWidth variant={tier.buttonVariant} disabled={true}>
                                            Your Current Plan
                                        </Button>
                                    }
                                    {
                                        plan === tier.title && tier.title !== 'FREE' &&
                                        <Button fullWidth variant={tier.buttonVariant} disabled={true}>
                                            Your Current Plan
                                        </Button>
                                    }
                                    {
                                        plan !== tier.title && tier.title !== 'FREE' &&
                                        <Button fullWidth variant={tier.buttonVariant} onClick={tier.function}>
                                            {tier.buttonText}
                                        </Button>
                                    }

                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <br />
                <Copon />
            </Container>
            <Dialog
                open={openErr}
                onClose={handleErrClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ERROR
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        { err}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleErrClose} autoFocus>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openUPD}
                onClose={handleUPDClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>Confirm that you want to switch from { state.plan }  subscription to  {newplan} subscription?</p>
                        <p> Warning Payment will be immediately</p>
                        <p>You will receive a provisional invoice document without tax validity </p>
                        <p>An invoice with tax validity will be issued within 24 hours after payment. </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="error"  onClick={handleUPDClose} autoFocus>Close</Button>
                    <Button color="success" onClick={confirmNewPlan} >Confirm</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDelUPD}
                onClose={handleDelUPDClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>Confirm that you want to switch from { state.plan }  subscription to  {newplan} subscription?</p>
                        <p> Warning deactivate will be immediately</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="success"  onClick={handleDelUPDClose} autoFocus>Close</Button>
                    <Button color="error" onClick={confirmDelPlan} >Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}



export default Fee;
