// res.data.value.client_secret



import {useDispatch, useSelector} from "react-redux";
import {selectorLoggedUser} from "../store/user/user.selector";
import {useNavigate} from "react-router";
import {useEffect, useState} from "react";
// import {useStripe} from "@stripe/react-stripe-js";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";
import axios from "axios";
// import {Elements} from "@stripe/react-stripe-js";
// import {loadStripe} from '@stripe/stripe-js';
import PaymentStatus from "../components/stripe/PaymentStatus";
import {updDateUser} from "../store/user/user.actions";
// const stripePromise = loadStripe('pk_test_51IHaDdGkZgdPCt2VJQ1xSqZe6TwFExbXSj0vuMn91Vv9PBlUL5mNvz3bISKG5C8cxv1IxuXOiehOTeqguyknZcK800VcVg2HkQ');


const RetunStipePage = () => {
    const state =useSelector(selectorLoggedUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const stripe = useStripe();
    const [message, setMessage] = useState('Completed now you can return to Select your new Subscription');
    const [title, setTitle] = useState('');
    const [open, setOpen] = useState(true);
    const [to, setTo] = useState('/fee');
    const client = localStorage.getItem('client');
    let options = {
        // passing the client secret obtained in step 3
        clientSecret: client,//'{{CLIENT_SECRET}}',
        // Fully customizable with appearance API.
        appearance: {
            theme: 'night',
            labels: 'floating'
        },
    };


    const handleErrClose = () => {
        //setOpen(false);

        let date = {
            addebitFee : true
        }
        axios.patch(`https://log.apserial.it/user/user`, date, {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
            if(res.status === 200 ) {
                dispatch(updDateUser({
                    name : 'addebitFee',
                    value : true
                }));
                navigate(to);
            }
        })



    };


    useEffect(
         () => {
             const Funx = async () => {
                 setTitle('Operations');
                 setMessage( await PaymentStatus().then((res) => res));

             }

             Funx();

        }

    );


    return (
    <div>
        <Dialog
            open={open}
            onClose={handleErrClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                { title.toUpperCase() }
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { message }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleErrClose} autoFocus>Go To Fee</Button>
            </DialogActions>
        </Dialog>
        {/*<Elements stripe={stripePromise} options={ options}>*/}
        {/*    */}
        {/*</Elements>*/}

    </div>
    )




}

export default RetunStipePage;
