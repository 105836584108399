import './App.css';
import { Route, Routes } from "react-router";
import LoginPage from "./page/login";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Dashboard from "./components/Dashboard";
import User from "./page/user";
import AppBarDev from "./components/AppBarDev";
import {RequireAuth} from "./components/RequireAuth";
import {ApiKeyPage} from "./page/ApiKeyPage";
import RegisterPage from "./page/register";
import LogPage from "./page/LogPage";
import InvoicePage from "./page/invoicePage";
import PayPage from "./page/pay";
import FeePage from "./page/feePage";
import RetunStipePage from "./page/returnStripePage";
import {CoponAdminPage} from "./page/admin/copon";
import {UsersAdminPage} from "./page/admin/users";
import {ChangePlanAdminPage} from "./page/admin/changePlan";
import {DocumentationPage} from "./page/documentation";
import ResetPasswordRequest from "./components/resetRequestPSW";
import ResetRequest from "./components/RequestPSW";



const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: [
      '-apple-system',
      '"Montserrat"',
      '"JetBrains Mono"',
      'monospace',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
});

function App() {



  return (
        <div className="App">
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Routes>
              <Route path="/" element={<RequireAuth> <AppBarDev>  <LogPage /> </AppBarDev> </RequireAuth>} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/reset" element={<ResetRequest/>} />
              <Route path="/user/changepassword" element={<ResetPasswordRequest/>} />
              <Route path="/user" element={<RequireAuth> <AppBarDev>  <User /> </AppBarDev> </RequireAuth>} />
              <Route path="/apikey" element={<RequireAuth> <AppBarDev>  <ApiKeyPage /> </AppBarDev> </RequireAuth>} />
              <Route path="/logview" element={<RequireAuth> <AppBarDev>  <LogPage /> </AppBarDev> </RequireAuth>} />
              <Route path="/doc" element={<RequireAuth> <AppBarDev>  <DocumentationPage /> </AppBarDev> </RequireAuth>} />
              <Route path="/invoice" element={<RequireAuth> <AppBarDev>  <InvoicePage /> </AppBarDev> </RequireAuth>} />
              <Route path="/fee" element={<RequireAuth> <AppBarDev>  <FeePage /> </AppBarDev> </RequireAuth>} />
              <Route path="/user/payments" element={
                <RequireAuth>
                  <AppBarDev>
                    <PayPage></PayPage>
                  </AppBarDev>
                </RequireAuth>} />
              <Route path="/account/payments/setup-complete" element={<RequireAuth> <AppBarDev>  <RetunStipePage /> </AppBarDev> </RequireAuth>} />
              <Route path="/admin/copon" element={<RequireAuth><AppBarDev> <CoponAdminPage />  </AppBarDev> </RequireAuth>} />
              <Route path="/admin/users" element={<RequireAuth><AppBarDev> <UsersAdminPage />  </AppBarDev> </RequireAuth>} />
              <Route path="/admin/change" element={<RequireAuth><AppBarDev> <ChangePlanAdminPage />  </AppBarDev> </RequireAuth>} />
            </Routes>
          </ThemeProvider>
        </div>


  );
}

export default App;
