import * as React from 'react';
import {useSelector} from "react-redux";
import {selectorLoggedUser} from "../store/user/user.selector";
import { useState} from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {Alert, Card, CardHeader, Grid, Snackbar} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";


export default function ApiKeys() {
  const state =useSelector(selectorLoggedUser);
  const [token, setToken] = useState('');
  const [open, setOpen] = useState(false);
  const columns = [
    { field: 'id', headerName: 'ID', width: 10 },
    {
      field: 'name',
      headerName: 'Name',
      width: 100,
      editable: false,
    },
    {
      field: 'token',
      headerName: 'ApiKey',
      width: 500,
      editable: false,
    },
    {
      field: 'active',
      headerName: 'Active',
      type: 'bool',
      width: 100,
      editable: false,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      sortable: false,
      width: 200,
    },
  ];
  //let rows = state.securityAccesses ? state.securityAccesses : [];

  const handleRowClick = (params) => {
    //////console.log(params);
    setToken(params.row.token);
    navigator.clipboard.writeText(params.row.token);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };




  return (
      <div>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={12} item>
              <Card>
                <CardHeader title={`Your Tokens`} subheader={'for copy a click on the row'} />
              </Card>
            </Grid>
            <Grid xs={12} item>
              <Card>
                <Box sx={{ height: 400, width: '100%' }}>
                  <DataGrid
                      onRowClick={handleRowClick} {...state.securityAccesses}
                      getRowId={(row) => row.id}
                      rows={state.securityAccesses ? state.securityAccesses : []}
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      checkboxSelection
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
        >

          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Copied Token!
          </Alert>

        </Snackbar>
      </div>
  );


}
