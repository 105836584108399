import * as React from 'react';
import {Component} from "react";
import Fee from "../components/fee";



class FeePage extends Component {
    render() {
        return (
            <div>
                <Fee/>
            </div>
        );
    }
}

export default FeePage
