
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {
    Accordion, AccordionDetails,
    AccordionSummary, Alert,
    Card,
    CardHeader,
    Grid, Snackbar,
    Typography
} from "@mui/material";
import * as React from "react";

import AddCircleIcon from '@mui/icons-material/AddCircle';

import GitHubIcon from '@mui/icons-material/GitHub';
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {useState} from "react";
import Button from "@mui/material/Button";

export const DocumentationPage = () => {
    const [open, setOpen] = useState(false);
    const [fitstopen, setfitstopen] = useState(true);
    const handleCodeClick = (event) => {
        navigator.clipboard.writeText(event.target.innerText);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handlefrist = (event) => {
        setfitstopen(!fitstopen);
    };

    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={12} item>
                        <Card>
                            <CardHeader title={`Documentation`}  />
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <br />
            <Accordion expanded={fitstopen} onChange={handlefrist}>
                <AccordionSummary
                    expandIcon={<AddCircleIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography >Getting Started</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <Typography>Starting Step!</Typography>

                        <Typography>1 - Create Your Token</Typography>
                        go to page "Token" > Click button "Add" > Set name of "Token"
                        <Typography>2 - Send Log!</Typography>
                        <Typography>Below is a Ready to Use function for getting started with APXC Log Engine</Typography>
                        attection required Axios!
                    </Typography>
                    <br />
                    <SyntaxHighlighter
                        onClick={handleCodeClick}
                        language="javascript"
                        style={atomOneDark}
                        customStyle={{
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'flex-start',
                            lineHeight: '1.5',
                            fontFamily: 'Menlo, monospace',
                            textAlign: 'left'
                        }}
                        wrapLines={true}
                        PreTag="div"
                        children={`                      
const axios = require('axios');

const TOKEN = 'YOUR_TOKEN'; // Modify THIS!
const URL_LOG = 'https://log.apserial.it/log/'
const ORIGIN = 'YOUR_ORIGIN';

const LEVEL = {
    INFO : 'INFO',
    ERROR : "ERROR",
    WARNING : "WARNING",
    COMPLETE : "COMPLETE",
    INIT : "INIT"
}

async function sendLog(level, message, action, object, group){
    let data = {
        "level": LEVEL[level],
        "message": message,
        "content": JSON.stringify(object),
        "origin": ORIGIN,
        "action": action,
        "group": group
    };

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: URL_LOG,
        headers: {
            'Authorization': Bearer /$'{TOKEN}' ,
            'Content-Type': 'application/json'
        },
        data : JSON.stringify(data)
    };

    axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
            console.log(error);
        });
}


async function InitialLog(obj, message, action, group) {
    await sendLog(LEVEL.INIT, message, action,obj, group);
}

async function ErrorLog (obj, message, action, group){
    await sendLog(LEVEL.ERROR, {message}, action, obj, group);

}
async function WarnLog (obj, message, action, group){
    await sendLog(LEVEL.WARNING,  message, action, obj, group);
}

async function InfoLog (obj,  message,action, group) {
    await sendLog(LEVEL.INFO,message}, action, obj, group);
}

async function CompleteLog (obj, message, action, group){
    await sendLog(LEVEL.COMPLETE, message, action, obj, group);
}


module.exports = {
    sendLog : sendLog,
    LEVEL : LEVEL,
    InitialLog : InitialLog,
    ErrorLog : ErrorLog,
    WarnLog : WarnLog,
    InfoLog : InfoLog,
    CompleteLog : CompleteLog
}
              
                        `.replace(/\n$/, "")}
                    />
                    <Typography>If you need a complete example</Typography>
                    <Button href={'https://github.com/APXc/apxc_log_engine_getstarted'} variant="contained" color="success" endIcon={<GitHubIcon />} size="large" sx={{ m: 2 }} >
                        Go to GitHub
                    </Button>

                </AccordionDetails>
            </Accordion>
            <br />
            <Accordion>
                <AccordionSummary
                    expandIcon={<AddCircleIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography >Add Log</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Request for add Logs on DB
                    </Typography>
                    <br />
                    <SyntaxHighlighter
                        onClick={handleCodeClick}
                        language="bash"
                        style={atomOneDark}
                        customStyle={{
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'flex-start',
                            lineHeight: '1.5',
                            fontFamily: 'Menlo, monospace',
                            textAlign: 'left'
                        }}
                        wrapLines={true}
                        PreTag="div"
                        children={`
# POST REQUEST                        
curl --location 'https://log.apserial.it/log/' \\ 
--header 'Authorization: Bearer {{Your Token}}' \\
--header 'Content-Type: application/json' \\
--data '{
    "level" : "INFO", # category/level log
    "message" : "ALFA", # free Text
    "content" : "{\\"NAME\\": \\"ALFA\\" }", # free Text
    "origin" : "FILE_TEST.CSV", # free Text
    "action" : "RESTART", # free Text
    "group" : "TEST" # free Text
}'
  `.replace(/\n$/, "")}
                    />

                    <br />

                    <Typography>
                        on Responde the detail of added log
                    </Typography>
                    <br />
                    <SyntaxHighlighter
                        onClick={handleCodeClick}
                        language="json"
                        style={atomOneDark}
                        customStyle={{
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'flex-start',
                            lineHeight: '1.5',
                            fontFamily: 'Menlo, monospace',
                            textAlign: 'left'
                        }}
                        wrapLines={true}
                        PreTag="div"
                        children={`{
    "code": "CREATED",
    "status": 201,
    "messages": "Success Operation",
    "value": {
        "id": 121674,
        "level": "INFO",
        "message": "ALFA",
        "content": "{\\"NAME\\": \\"ALFA\\" }",
        "origin": "FILE_TEST.CSV",
        "action": "RESTART",
        "group": "TEST",
        "authId": 57,
        "updatedAt": "2023-02-23T20:24:23.660Z",
        "createdAt": "2023-02-23T20:24:23.660Z"
    }
}
  `.replace(/\n$/, "")}
                    />
                    <br />
                    <Typography>
                       Available Level
                    </Typography>
                    <br />
                    <SyntaxHighlighter
                        onClick={handleCodeClick}
                        language="json"
                        style={atomOneDark}
                        customStyle={{
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'flex-start',
                            lineHeight: '1.5',
                            fontFamily: 'Menlo, monospace',
                            textAlign: 'left'
                        }}
                        wrapLines={true}
                        PreTag="div"
                        children={`{
     "value": [
            "INFO", // Suggested For Low Log 
            "ERROR", //Suggested For Error Log and Important Log
            "WARNING", 
            "COMPLETE", // Suggested For Log to completed operations Flow
            "INIT" // Suggested For Log to init operations Flow
        ]
    }
}
  `.replace(/\n$/, "")}
                    />
                </AccordionDetails>
            </Accordion>
            <br />
            <Accordion>
                <AccordionSummary
                    expandIcon={<AddCircleIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography >Get Logs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                      <Typography>For Get Log Searched request</Typography>
                        Available Params : Level, Group, Origin, Message, Content, Action
                    </Typography>
                    <br />
                    <SyntaxHighlighter
                        onClick={handleCodeClick}
                        language="bash"
                        style={atomOneDark}
                        customStyle={{
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'flex-start',
                            lineHeight: '1.5',
                            fontFamily: 'Menlo, monospace',
                            textAlign: 'left'
                        }}
                        wrapLines={true}
                        PreTag="div"
                        children={`
# GET REQUEST                        
curl --location 'https://log.apserial.it/log
                        ?level=INFO 
                        &group=TEST
                        &origin=TEST
                        &message=TEST
                        &content=TEST
                        &action=TEST' \\
--header 'Authorization: Bearer {{Your Token}}' \\
--data '''
  `.replace(/\n$/, "")}
                    />

                    <br />
                    <Typography>
                        <Typography> For Get All Log request </Typography>
                        Available Params : limit, startDate, endDate
                    </Typography>
                    <br />
                    <SyntaxHighlighter
                        onClick={handleCodeClick}
                        language="bash"
                        style={atomOneDark}
                        customStyle={{
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'flex-start',
                            lineHeight: '1.5',
                            fontFamily: 'Menlo, monospace',
                            textAlign: 'left'
                        }}
                        wrapLines={true}
                        PreTag="div"
                        children={`
# GET REQUEST                        
curl --location 'https://log.apserial.it/log/all
                        ?limit=1000
                        &startDate=2023-03-12T23:00:00.000
                        &endDate=2023-03-31T23:00:00.000' \\
--header 'Authorization: Bearer {{Your Token}}' \\
--data '''
  `.replace(/\n$/, "")}
                    />
                    <br />
                    <Typography>
                        on Responde the detail of logs
                    </Typography>
                    <br />
                    <SyntaxHighlighter
                        onClick={handleCodeClick}
                        language="json"
                        style={atomOneDark}
                        customStyle={{
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'flex-start',
                            lineHeight: '1.5',
                            fontFamily: 'Menlo, monospace',
                            textAlign: 'left'
                        }}
                        wrapLines={true}
                        PreTag="div"
                        children={`{
    "code": "OK",
    "status": 200,
    "messages": "Success Operation",
    "value": [
        {
            "id": 121678,
            "level": "ERROR",
            "message": "ALFA",
            "content": "{\\"NAME\\": \\"ALFA\\" }",
            "origin": "FILE_TEST.CSV",
            "action": "RESTART",
            "group": "TEST",
            "createdAt": "2023-03-13T21:26:50.226Z",
            "updatedAt": "2023-03-13T21:26:50.226Z",
            "authId": 2
        },
        {
            "id": 121689,
            "level": "INFO",
            "message": "ALFA",
            "content": "{\\"NAME\\": \\"ALFA\\" }",
            "origin": "FILE_TEST.CSV",
            "action": "RESTART",
            "group": "TEST",
            "createdAt": "2023-03-13T21:26:42.663Z",
            "updatedAt": "2023-03-13T21:26:42.663Z",
            "authId": 2
        },
        {
            "id": 121568,
            "level": "INIT",
            "message": "ALFA",
            "content": "{\\"NAME\\": \\"ALFA\\" }",
            "origin": "FILE_TEST.CSV",
            "action": "RESTART",
            "group": "TEST",
            "createdAt": "2023-03-13T21:26:15.679Z",
            "updatedAt": "2023-03-13T21:26:15.679Z",
            "authId": 2
        }
    ]
}
  `.replace(/\n$/, "")}
                    />
                    <br />


                </AccordionDetails>
            </Accordion>
            <br />
            <Accordion>
                <AccordionSummary
                    expandIcon={<AddCircleIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography >Support Request</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <Typography>For HelpDesk, Suggest, Info or Other Request</Typography>
                        Send Mail to This Email Address
                    </Typography>
                    <br />
                    <SyntaxHighlighter
                        onClick={handleCodeClick}
                        language="bash"
                        style={atomOneDark}
                        customStyle={{
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'flex-start',
                            lineHeight: '1.5',
                            fontFamily: 'Menlo, monospace',
                            textAlign: 'left'
                        }}
                        wrapLines={true}
                        PreTag="div"
                        children={`support@apserial.it`.replace(/\n$/, "")}
                    />
                </AccordionDetails>
            </Accordion>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >

                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Copied Content!
                </Alert>

            </Snackbar>
        </div>
    );

}
