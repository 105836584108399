import React, {useState, useEffect} from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { darken, lighten, styled } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';
import StraightenIcon from '@mui/icons-material/Straighten';
import {useSelector} from "react-redux";
import {selectorLoggedUser} from "../store/user/user.selector";
import {
    Alert,
    Card, CardActions,
    CardHeader,
    CircularProgress,
    FormControl, FormGroup,
    InputLabel,
    MenuItem,
    Select, Snackbar, Switch
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { DateTime } from "luxon";
import CssBaseline from "@mui/material/CssBaseline";
import {DataGrid , GridToolbar } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import 'dayjs/locale/it';
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from "@mui/material/FormControlLabel";




function LogView() {
    const state =useSelector(selectorLoggedUser);
    const [token, setToken] = useState('');
    const [filter, setFilter] = useState({
        limit : 10000,
         startDate : DateTime.now().minus({ days: 30 }).toJSDate(),
        endDate : DateTime.now().plus({days: 1 }).toJSDate()
    });
    const [open, setOpen] = useState(false);
    const [autoupdate, setAutoupdate] = useState(true);
    const [onLoop, setOnLoop] = useState(false);
    const [autoupdateTime, setAutoupdateTime] = useState(60000);
    const [columnSize, setColumnSize] = useState({
        id : 10,
        level : 100,
        message : 150,
        content : 250,
        origin : 150,
        group : 100,
        createdAt : 100,
    })


    const [view, setView] = useState(
        {
            loading : false,
            logs : [],
        }
    )
    const [openViewLog, setOpenViewLog] = useState(false);
    const [log, setLog] = useState({});

    let rows = state.securityAccesses ? state.securityAccesses : [];

    const columns = [
        { field: 'id', headerName: 'ID', width: 10 },
        {
            field: 'level',
            headerName: 'Level',
            width: 100,
            editable: false,
        },
        {
            field: 'message',
            headerName: 'Message',
            width: 150,
            editable: false,
        },
        {
            field: 'content',
            headerName: 'Content',
            type: 'bool',
            width: 250,
            editable: false,
        },
        {
            field: 'origin',
            headerName: 'Origin',
            type: 'bool',
            width: 150,
            editable: false,
        },
        {
            field: 'action',
            headerName: 'action',
            type: 'bool',
            width: 100,
            editable: false,
        },
        {
            field: 'group',
            headerName: 'group',
            type: 'bool',
            width: 100,
            editable: false,
        },
        {
            field: 'createdAt',
            headerName: 'Created At (UTC Time)',
            type : 'date',
            sortable: false,
            width: 200,
        },
    ];

    const handleChange = (event) => {
        setToken(event.target.value);
        if(event.target.value !== '') {
            setView({...view, loading: true});
            getLog(event.target.value);
        }

    };

    const handleRowClick = (params) => {
        navigator.clipboard.writeText(JSON.stringify(params.row));
        setOpen(true);
    };


    const handleReloadClick = (params) => {
        //setView({...view, loading: true});
        getLog(token);
    };
    const handleDoubleClick = (params) => {
        setLog(params.row);
        setOpenViewLog(true);
    };

    const handleLogClose = (event) => {
        setOpenViewLog(false);
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const getLog = (Itoken) => {
        axios.get('https://log.apserial.it/log/all', {  params: filter , headers : {'Authorization' : `Bearer ${Itoken}` }}).then((res) => {
            if(res.status === 200 ) {
                let data = res.data.value;
                setView({...view, loading: false, logs: data});
            }
            else {
                ////console.log('error ');
            }
        }).catch((err) =>{
            ////console.log(err);
        })
    }


    const getBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

    const getHoverBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getSelectedBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    const getSelectedHoverBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        '& .super-app-theme--Open': {
            backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        theme.palette.info.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
        '& .super-app-theme--Filled': {
            backgroundColor: getBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        theme.palette.success.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
        '& .super-app-theme--PartiallyFilled': {
            backgroundColor: getBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        theme.palette.warning.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
        '& .super-app-theme--Rejected': {
            backgroundColor: getBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        theme.palette.error.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
    }));


    useEffect(() => {

        if(rows.length === 1 && token === '') {
            setToken(state.securityAccesses[0].token);
            //setView({...view, loading: true});
            getLog(state.securityAccesses[0].token);
        }

        if(autoupdate && token !== '' && !onLoop) {
            setOnLoop(true);
            setTimeout(() => {
                //setView({...view, loading: true});
                getLog(token);
                setOnLoop(false);
            }, autoupdateTime)
        }

    }, [rows.length, token, state.securityAccesses, view, getLog, autoupdate, autoupdateTime, onLoop, setOnLoop]);


    return (
        <div>
            <FormControl sx={{ m: 5, minWidth: 300 }}>
                <InputLabel id="select-autowidth-label">Select Token</InputLabel>
                <Select
                    labelId="select-autowidth-label"
                    id="select-autowidth"
                    value={token}
                    onChange={handleChange}
                    label="Token"
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {rows.map((row) => (
                    <MenuItem
                        key={row.id}
                        value={row.token}>
                        {row.name}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
            <br />
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }} >
                <TextField
                    type="number"
                    label="Limit"
                   // value="10000"
                    hiddenLabel
                    id="limit"
                    //defaultValue="10000"
                    variant="outlined"
                    sx={{ width: '100%', flex: '1', marginRight: '10px' }}
                    onChange={(event) => {
                        const value = event.target.value;
                        setFilter({...filter, limit : value});
                        if(token !== '') {
                            getLog(token);
                        }
                    }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                    <DatePicker
                        label="StartDate"
                        defaultValue={dayjs(DateTime.now().minus({ days: 30 }).toJSDate())}
                        sx={{ width: '100%', flex: '1', marginRight: '10px' }}
                        onChange={(newValue) => {
                            const value = newValue.toJSON();
                            setFilter({...filter, startDate : value.toString()});
                            if(token !== '') {
                                getLog(token);
                            }
                        }}
                    />
                    <DatePicker
                        label="EndDate"
                        defaultValue={dayjs(DateTime.now().plus({days: 1}).toJSDate())}
                        sx={{ width: '100%', flex: '1', marginRight: '10px' }}
                        onChange={(newValue) => {
                            const value = newValue.toJSON();
                            setFilter({...filter, endDate : value.toString()});
                            if(token !== '') {
                                getLog(token);
                            }
                        }}
                    />
                </LocalizationProvider>
            </Box>
            <br />

            {
                view.loading &&
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '10vh' }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </Grid>
            }
            {
                view.logs !== [] &&
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid xs={12} item>
                            <Card>
                                <CardHeader   title={`Your Logs`} subheader={'for copy a click on the row, double clicks to open the detail'}  />
                                {/*action={<IconButton aria-label="Refresh" onClick={handleReloadClick}> <RefreshIcon/> </IconButton>}*/}
                                <CardActions>
                                    <IconButton aria-label="Refresh" onClick={handleReloadClick} > <RefreshIcon />  </IconButton>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch  checked={autoupdate} onChange={() => {
                                            setAutoupdate(!autoupdate);
                                        }} />} label="AutoUpdate View" />
                                    </FormGroup>
                                    {/*<Button variant="contained" endIcon={<StraightenIcon />}>*/}
                                    {/*    Resize Colums*/}
                                    {/*</Button>*/}
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid xs={12} item>
                            <Card>
                                <Box sx={{ height: 1000, width: '100%' }}>
                                    <StyledDataGrid
                                        slots={{ toolbar: GridToolbar }}
                                        getRowClassName={(params) => {
                                            const level = params.row.level;
                                            if (level === "ERROR") {
                                                return "super-app-theme--Rejected";
                                            } else if (level === "COMPLETE") {
                                                return "super-app-theme--Filled";
                                            }
                                            else if (level === "WARNING") {
                                                return "super-app-theme--PartiallyFilled";
                                            }
                                            else if (level === "INFO") {
                                                return "super-app-theme--Open";
                                            }
                                            return "";
                                        }}
                                        onRowClick={handleRowClick} {...view.logs}
                                        onRowDoubleClick={handleDoubleClick} {...view.logs}
                                        getRowId={(row) => row.id}
                                        rows={view.logs}
                                        columns={columns}
                                        pageSize={50}
                                        rowsPerPageOptions={[10]}
                                        checkboxSelection
                                        disableSelectionOnClick
                                        experimentalFeatures={{ newEditingApi: true }}

                                    />
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>

            }
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >

                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Copied Row on JSON Format!
                </Alert>

            </Snackbar>
            <Dialog
                fullScreen
                open={openViewLog}
                onClose={handleLogClose}
                //TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleLogClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Log Details #{log.id}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleLogClose}>
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItem button>
                        <ListItemText primary={log.level} secondary="level" />
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText
                            primary={log.message}
                            secondary="message"
                        />
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText
                            primary={log.content}
                            secondary="content"
                        />
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText
                            primary={log.origin}
                            secondary="origin"
                        />
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText
                            primary={log.action}
                            secondary="action"
                        />
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText
                            primary={log.group}
                            secondary="group"
                        />
                    </ListItem>
                    <Divider />
                    <ListItem button>
                        <ListItemText
                            primary={log.createdAt}
                            secondary="created at"
                        />
                    </ListItem>
                </List>
            </Dialog>
        </div>
    );
}

export default LogView;
