import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {selectorLoggedUser} from "../store/user/user.selector";
import {useNavigate} from "react-router";
import {loginUserAction} from "../store/user/user.actions";

export const RequireAuth = ({children}) => {
    const dispatch = useDispatch();
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    const state =useSelector(selectorLoggedUser);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user && JSON.stringify(state) === '{}') {
            navigate("/login")
        }
        else if(JSON.stringify(state) === '{}') {
            let my = JSON.parse(user);
            my['token'] = token;
            dispatch(loginUserAction(my));
        }
    })

    return <div>{children}</div>


}
