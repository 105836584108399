import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import axios from "axios";
import {loginUserAction} from "../store/user/user.actions";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {useState} from "react";



function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://log.apserial.it/">
                Pellizzaro Development Projects
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary : {
            main : '#198754'
        },
        background: {
            default : '#212529' //#198754 //#212529
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            '"Montserrat"',
            '"JetBrains Mono"',
            'monospace',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    }
});


const theme = darkTheme;


export default function SignUp () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [statError, setStatError] = React.useState(false);
    const [err, setErr] = useState('Your Data is incorrect');
    const [checkedPrivacy, setCheckedPrivacy] = React.useState(false);
    const [checkedTeC, setCheckedTeC] = React.useState(false);
    const [checkedMKT, setCheckedMKT] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setStatError(false);
        setOpen(false);
    };

    const handleChangePrivacy = (event) => {
        setCheckedPrivacy(event.target.checked);
    };

    const handleChangeTeC = (event) => {
        setCheckedTeC(event.target.checked);
    };
    const handleChangeMKT = (event) => {
        setCheckedMKT(event.target.checked);
    };

    const viewsTerms = (event) => {
        event.preventDefault();
        window.open(`https://www.iubenda.com/terms-and-conditions/65555249`, "terms", 'location=yes,height=570,width=520,scrollbars=yes,status=yes"')
    };
    const viewsPolicy= (event) =>  {
        event.preventDefault();
        window.open(`https://www.iubenda.com/privacy-policy/65555249/legal`, "policy", 'location=yes,height=570,width=520,scrollbars=yes,status=yes"')
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if(!data.has('password') || data.get('password') === '' || data.get('password') === null) {
            setErr('you did not insert the password value!');
            setStatError(true);
            handleClickOpen();
        }
        else if (!data.has('firstName') || data.get('firstName') === '' || data.get('firstName') === null) {
            setErr('you did not insert the FirstName value!');
            setStatError(true);
            handleClickOpen();

        }
        else if (!data.has('email') || data.get('email') === '' || data.get('email') === null) {
            setErr('you did not insert the Email value!');
            setStatError(true);
            handleClickOpen();
        }

        else if (!data.has('lastName') || data.get('lastName') === '' || data.get('lastName') === null) {
            setErr('you did not insert the LastName value!');
            setStatError(true);
            handleClickOpen();
        }
        else if(!checkedPrivacy){
            setErr('you did not have access to the privacy conditions!');
            setStatError(true);
        }
        else if(!checkedTeC){
            setErr('you did not have access to the Terms & conditions!');
            setStatError(true);
        }
        else {
            let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            let testEmail=  mailformat.test(data.get('email'));


            let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            let testPassword =  strongRegex.test(data.get('password'));

            if(!testPassword) {
                setErr('Attention the password must contain at least 1 lowercase alphabetic character, at least 1 uppercase alphabetic character, at least 1 numeric character, at least one special character [! @ # $% ^ & *] E must be at least 8 characters long');
                setStatError(true);
                handleClickOpen();
            }
            else if(!testEmail){
                setErr(' Attention Email is not valid!');
                setStatError(true);
                handleClickOpen();
            }
            else {
                if(!statError) {
                    axios.post('https://log.apserial.it/user/register', { 'email' : data.get('email'), 'password' : data.get('password'), 'name' : data.get('firstName'), 'surname' : data.get('lastName') }).then((res) => {
                        if(res.status === 201 ) {
                            localStorage.setItem('token', `${res.data.token}`);
                            localStorage.setItem('user', JSON.stringify(res.data.user));
                            let dataUser = res.data.user;
                            dataUser.token =  res.data.token;
                            dataUser.isFirstLogin = true;
                            dispatch(loginUserAction(dataUser));
                            navigate("/");
                        }
                        else {
                            setErr(res.data.message);
                            handleClickOpen();
                        }


                    }).catch((err) =>{
                       // //console.log(err);
                        setErr(err.response.data.error);
                        handleClickOpen();
                    })
                }
            }
        }

    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="family-name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox value="allowTermCondition" color="primary" checked={checkedTeC} onChange={handleChangeTeC} />}
                                    label="I accept terms and conditions required to access the services by APXC Log Engine*"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox value="allowPrivacy" color="primary" checked={checkedPrivacy} onChange={handleChangePrivacy} />}
                                    label="I accept privacy policy required to access the services by APXC Log Engine*"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox value="allowExtraEmails" color="primary" checked={checkedMKT} onChange={handleChangeMKT} />}
                                    label="I want to receive inspiration, marketing promotions and updates via email."
                                />
                            </Grid>

                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </Button>
                        <br />
                        <Grid container>
                            <Grid item xs={5} sx={{ mr: 2}}>
                                <Button  onClick={viewsPolicy} variant="contained" fullWidth>View Privacy Policy</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button onClick={viewsTerms}  variant="contained"  fullWidth>View Terms & Conditions</Button>
                            </Grid>


                        </Grid>
                        <br />
                        <Grid container>
                            <Grid item>
                                <Link href="https://app.apserial.it/login" variant="body2">
                                    {"Don't have an account? Go to SignIn"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        ERROR
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            { err}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} autoFocus>Close</Button>
                    </DialogActions>
                </Dialog>

                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>
    );
}
