import * as React from 'react';
import {useSelector} from "react-redux";
import {selectorLoggedUser} from "../store/user/user.selector";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {Alert, Card, CardHeader, Grid, Snackbar} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {useState} from "react";


export default function InvoiceList() {
    const state =useSelector(selectorLoggedUser);
    const [token, setToken] = useState('');
    const [view, setView] = useState(
        {
            loading : false,
            invoice : [],
        }
    )
    const [open, setOpen] = useState(false);
    const columns = [
        { field: 'id', headerName: 'ID', width: 10 },
        {
            field: 'name',
            headerName: 'Name',
            width: 250,
            editable: false,
        },
        {
            field: 'addebit',
            headerName: 'Addebit',
            type: 'bool',
            width: 100,
            editable: false,
        },
        {
            field: 'state',
            headerName: 'State',
            width: 100,
            editable: false,
        },
        {
            field: 'valueToken',
            headerName: 'Max Token',
            width: 100,
        },
        {
            field: 'valueLogs',
            headerName: 'Max Logs for Period',
            width: 100,
        },
        {
            field: 'excedentLogs',
            headerName: 'Excedent Logs for Period',
            width: 100,
        },
        {
            field: 'valueExcedentPrice',
            headerName: 'Price Excedent Logs for Period',
            width: 100,
        },
        {
            field: 'valueFeePrice',
            headerName: 'Price Fee',
            width: 100,
        },
        {
            field: 'valueTotalPrice',
            headerName: 'Total Price ',
            width: 100,
        },
    ];

    const handleRowClick = (params) => {
        ////console.log(params);
        navigator.clipboard.writeText(JSON.stringify(params.row));
        setOpen(true);
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={12} item>
                        <Card>
                            <CardHeader title={`Your Invoice`}  />
                        </Card>
                    </Grid>
                    <Grid xs={12} item>
                        <Card>
                            <Box sx={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    onRowClick={handleRowClick} {...state.renews}
                                    getRowId={(row) => row.id}
                                    rows={state.renews ? state.renews : []}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    checkboxSelection
                                    disableSelectionOnClick
                                    experimentalFeatures={{ newEditingApi: true }}
                                />
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >

                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Copied Row on JSON Format!
                </Alert>

            </Snackbar>
        </div>
    );


}
