
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import {selectorLoggedUser} from "../store/user/user.selector";
import {useDispatch, useSelector} from "react-redux";
import {
    Card, CardActions,
    CardContent,
    CardHeader, Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import {loginUserAction, updDateUser} from "../store/user/user.actions";
import {useNavigate} from "react-router";
import {useState} from "react";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TextField from "@mui/material/TextField";

function User() {
    const state =useSelector(selectorLoggedUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [filedList, setfiledList] = useState([]);
    const [openErr, setOpenErr] = useState(false);
    const [openUPD, setOpenUPD] = useState(false);
    const [err, setErr] = useState('');
    const [mod, setMod] = useState(false);
    const [openDelUPD, setOpenDelUPD] = useState(false);
    const [newplan, setNewPlan] = useState('');
    let rows = [
        {
            name : 'nation',
            value : state.nation,
        },
        {
            name : 'street',
            value : state.street,
        },
        {
            name : 'zipCode',
            value : state.zipCode,
        },
        {
            name : 'city',
            value : state.city,
        },
        {
            name : 'province',
            value : state.province,
        },
        {
            name : 'companyName',
            value : state.companyName,
        },
        {
            name : 'fiscalCode',
            value : state.fiscalCode,
        },
        {
            name : 'taxID',
            value : state.taxID,
        },
        {
            name : 'eInvoiceCode',
            value : state.eInvoiceCode,
        },
    ]

    const columns = [
        { field: 'name', headerName: 'Field', width: 150 },
        {
            field: 'value',
            headerName: 'Value',
            width: 300,
            editable: true,
        },

    ];
    const handleRowClick = (params) => {
        ////console.log(params);

        setfiledList([...filedList, { name : params.name, value : params.value}]);

    };

    const handleErrOpen = (msg) => {
        setOpenErr(true);


    };

    const handleErrClose = () => {
        setOpenErr(false);
    };

    const handleUPDOpen = () => {
        setOpenUPD(true);


    };

    const handleDelUPDClose = () => {
        setOpenDelUPD(false);
    };


    const handleUPDClose = () => {
        setOpenUPD(false);
    };


    const addSubmit = (event) => {
        event.preventDefault();
        if(!state.nation || !state.street || !state.zipCode || !state.province ) {
            setErr('your Profile is not complete, before proceeding make sure you have entered this information \n nation,\n street,\n  zipCode,\n province,\n if any Company Name, \n if any fiscalCode, \n if any TaxID, \n if any eInvoiceCode');
            setOpenErr(true);
        }
        else {
            navigate('/fee');
        }

    }

    const addFREE = (event) => {
        event.preventDefault();

        if(state.plan !== 'FREE') {
            setNewPlan('FREE');
            setOpenDelUPD(true);
        }
        else {
            setErr('You have a Free Plan');
            setOpenErr(true);
        }

    }

    const confirmDelPlan = (event) => {
        event.preventDefault();
        axios.delete(`https://log.apserial.it/user/sub`,  {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
            if(res.status === 200 ) {
                axios.get('https://log.apserial.it/user/loginMe', {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
                    if(res.status === 200 ) {
                        let user = res.data.user;
                        user.token =  state.token;
                        localStorage.setItem('user', JSON.stringify(user));
                        dispatch(loginUserAction(user));
                        navigate('/');
                    }
                });
            }
        }).catch((err) =>{
            setErr(err.response.data.message);
            setOpenErr(true);
            //////console.log(err.response.data.error);
        })

    }


    const saveModify = (event) => {
        let  date = {};

        for (let rw of filedList) {
            dispatch(updDateUser(rw));

            date[rw.name] = rw.value;
        }

        axios.patch(`https://log.apserial.it/user/user`, date, {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
            if(res.status === 200 ) {
                navigate('/');
                //Reload();
            }
        }).catch((err) =>{
            setErr(err.response.data.message);
            setOpenErr(true);
           // ////console.log(err.response.data.error);
        })
    }


    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={12} item>
                        <Card>
                            <CardHeader title={`${state.name.toUpperCase()} ${state.surname.toUpperCase()}`} subheader={'Your Name'} />
                        </Card>
                    </Grid>
                    <Grid xs={12} item>
                        <Card>
                            <CardHeader title={state.email} subheader={'Email'}/>
                        </Card>
                    </Grid>
                    <Grid xs={6} item>
                        <Card>
                            <CardHeader title={`My Data`}/>
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    Set Your Data
                                </Typography>
                                <Grid xs={12} item>
                                    <Card>
                                        <Box sx={{ height: 500, width: '100%' }}>
                                            <DataGrid
                                                //onRowClick={handleRowClick} {...view.logs}
                                                getRowId={(row) => row.name}
                                                rows={rows}
                                                columns={columns}
                                                pageSize={15}
                                                rowsPerPageOptions={[15]}
                                                checkboxSelection
                                                disableSelectionOnClick
                                                experimentalFeatures={{ newEditingApi: true }}
                                                onCellEditStart={(params) => {
                                                    setMod(true);
                                                }}
                                                processRowUpdate={handleRowClick} {...rows}
                                            />
                                        </Box>
                                    </Card>
                                </Grid>
                                <Button disabled={!mod} variant="contained" color="success" endIcon={<SaveIcon />} size="large" sx={{ m: 2 }} onClick={ saveModify}>
                                    Save
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={6} item>
                        <Card>
                            <CardHeader title={`My Fee`} />
                            <CardContent>
                                <Card>
                                    <CardHeader
                                        title={`Fee Details`}
                                        subheader={ `Next Renew ${   state.renewFee ? state.renewFee.split('T')[0] : 'NO DATA'}`}
                                        titleTypographyProps={{ align: 'center' }}
                                        //action={tier.title === 'Pro' ? <StarIcon /> : null}
                                        subheaderTypographyProps={{
                                            align: 'center',
                                        }}
                                        sx={{
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'light'
                                                    ? theme.palette.grey[200]
                                                    : theme.palette.grey[900],
                                        }}
                                    />
                                    <CardContent>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'baseline',
                                                mb: 2,
                                            }}
                                        >
                                            <Typography component="h2" variant="h3" color="text.primary">
                                                €{state.priceFee}
                                            </Typography>
                                            <Typography variant="h6" color="text.secondary">
                                                /30days
                                            </Typography>
                                        </Box>

                                            <Typography
                                                component="li"
                                                variant="subtitle1"
                                                align="center"
                                                key={`maxToken`}
                                            >
                                               You Have Max Token : {state.maxToken}
                                            </Typography>
                                            <Typography
                                                component="li"
                                                variant="subtitle1"
                                                align="center"
                                                key={`maxLogsMonth`}
                                            >
                                                You Have Max Logs for Token : {state.maxLogsMonth}
                                            </Typography>
                                            <Typography
                                                component="li"
                                                variant="subtitle1"
                                                align="center"
                                                key={`acceptExcedent`}
                                            >
                                                You accept Excedent : {state.acceptExcedent ? 'Accept' : 'Not Accept'}
                                            </Typography>
                                            <Typography
                                                component="li"
                                                variant="subtitle1"
                                                align="center"
                                                key={`priceExcedent`}
                                            >
                                                You Have This price for Excedent : {  state.priceExcedent}
                                            </Typography>

                                    </CardContent>
                                    <CardActions >
                                        <Button variant="contained" color="success" endIcon={<AddCircleIcon />} size="large" sx={{ m: 2 }} onClick={addSubmit}>
                                            Upgrade My Fee
                                        </Button>
                                        <Button variant="contained" color="error" endIcon={<HighlightOffIcon />} size="large" sx={{ m: 2 }} onClick={addFREE} >
                                            Deactivate My Fee
                                        </Button>
                                    </CardActions>
                                </Card>


                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
            <Dialog
                open={openErr}
                onClose={handleErrClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ERROR
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        { err}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleErrClose} autoFocus>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openUPD}
                onClose={handleUPDClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Select Your Fee limit
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <TextField
                            onChange={(event) => {
                                const value = event.target.value;
                               // setApiKey({...apiKey, apiKeyName : value});
                            }}
                            autoFocus
                            //value={state.maxToken}
                            margin="dense"
                            id="name"
                            label="maxToken"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUPDClose} autoFocus>Close</Button>
                    <Button >Upgrade</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDelUPD}
                onClose={handleDelUPDClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>Confirm that you want to switch from { state.plan }  subscription to  {newplan} subscription?</p>
                        <p> Warning deactivate will be immediately</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="success"  onClick={handleDelUPDClose} autoFocus>Close</Button>
                    <Button color="error" onClick={confirmDelPlan} >Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default User;
