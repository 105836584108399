import {useSelector} from "react-redux";
import {selectorLoggedUser} from "../../store/user/user.selector";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Grid,
    Typography
} from "@mui/material";
import * as React from "react";
import { useState} from "react";
import Button from "@mui/material/Button";
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from "axios";
import {DataGrid} from "@mui/x-data-grid";

export const ChangePlanAdminPage = () => {
    const state =useSelector(selectorLoggedUser);
    const [apiKey, setApiKey] = useState({
        apiKeyName : '',
        onAdd : false,
        onDelete : false,
        erroreMsg : ''
    });
    const [change, setChange] = useState([]);
    const [openErr, setOpenErr] = useState(false);
    const handleErrClose = () => {
        setOpenErr(false);
    };



    const Reload = (event) => {
        axios.get('https://log.apserial.it/admin/change', {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
            if(res.status === 200 ) {
                setChange(res.data.value);
            }
            else {
                setApiKey({...apiKey, erroreMsg :'ERROR GENERIC'} );
                setOpenErr(true);
            }
        }).catch((err) =>{
            setApiKey({...apiKey, erroreMsg : err.response.data.error} );
            setOpenErr(true);
        })
    }


    const columns = [
        { field: 'id', headerName: 'ID', width: 10 },
        {
            field: 'from',
            headerName: 'from',
            width: 200,
            editable: false,
        },
        {
            field: 'to',
            headerName: 'to',
            width: 200,
            editable: false,
        },
        {
            field: 'state',
            headerName: 'state',
            width: 100,
            editable: false,
        },
        {
            field: 'userId',
            headerName: 'userId',
            width: 100,
            editable: false,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            type: "date",
            sortable: false,
            width: 200,
        },
    ];


    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={12} item>
                        <Card>
                            <CardHeader title={`change Manage`}  />
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    Action
                                </Typography>
                                <Button variant="contained" color="info" endIcon={<RefreshIcon />} size="large" sx={{ m: 2 }} onClick={Reload} >
                                    Refresh
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} item>
                        <Box sx={{ display: 'flex' }}>
                            <CssBaseline />
                            <Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid xs={12} item>
                                    <Card>
                                        <CardHeader title={`Users`}  />
                                    </Card>
                                </Grid>
                                <Grid xs={12} item>
                                    <Card>
                                        <Box sx={{ height: 400, width: '100%' }}>
                                            <DataGrid
                                                //onRowClick={handleRowClick} {...state.securityAccesses}
                                                getRowId={(row) => row.id}
                                                rows={change ? change : []}
                                                columns={columns}
                                                pageSize={5}
                                                rowsPerPageOptions={[5]}
                                                checkboxSelection
                                                disableSelectionOnClick
                                                experimentalFeatures={{ newEditingApi: true }}
                                            />
                                        </Box>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>

                    </Grid>
                </Grid>
            </Box>
            <Dialog
                open={openErr}
                onClose={handleErrClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ERROR
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        { apiKey.erroreMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleErrClose} autoFocus>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}
