import {useDispatch, useSelector} from "react-redux";
import {selectorLoggedUser} from "../store/user/user.selector";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, FormControl,
    Grid, InputLabel, MenuItem, Select,
    Typography
} from "@mui/material";
import * as React from "react";
import { useState} from "react";

import ApiKeys from "../components/ApiKeys";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import RefreshIcon from '@mui/icons-material/Refresh';
import {useNavigate} from "react-router";
import TextField from "@mui/material/TextField";
import axios from "axios";
import {apiKeyUserActionGet} from "../store/user/user.actions";

export const ApiKeyPage = () => {
    const state =useSelector(selectorLoggedUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [apiKey, setApiKey] = useState({
        apiKeyName : '',
        onAdd : false,
        onDelete : false,
        erroreMsg : ''
    });
    const [open, setOpen] = useState(false);
    const [openDel, setOpenDel] = useState(false);
    const [openErr, setOpenErr] = useState(false);
    const [token, setToken] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelClickOpen = () => {
        setOpenDel(true);
    };

    const handleDelClose = () => {
        setOpenDel(false);
    };

    const handleErrOpen = (msg) => {
        setOpenErr(true);


    };

    const handleErrClose = () => {
        setOpenErr(false);
    };


    const handleChange = (event) => {
        setToken(event.target.value);
    };

    const addError = (event) => {
        //let msg = 'err';
        event.preventDefault();
        //console.log('OPERN ERR');
        handleErrOpen(apiKey.erroreMsg);
    }

    const addSubmit = (event) => {
        event.preventDefault();
        handleClickOpen();
        setApiKey({...apiKey, onAdd : true});
    }

    const delSubmit = (event) => {
        event.preventDefault();
        handleDelClickOpen();
    }

    const ConfirmAdd = (event) => {

        setOpen(false);
        axios.post('https://log.apserial.it/sec', { 'name' : apiKey.apiKeyName }, {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
            if(res.status === 200 ) {
                Reload();
            }
            else  {

                //evento('',addError);
                //addError;

            }
        }).catch((err) =>{
            setApiKey({...apiKey, erroreMsg : err.response.data.error} );
            setOpenErr(true);
            //console.log(err.response.data.error);
        })
    }

    const ConfirmDel = (event) =>{
        if(token !== '') {
            setOpenDel(false);
            axios.delete(`https://log.apserial.it/user/apikey/${token}`, {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
                if(res.status === 200 ) {
                    Reload();
                }
            }).catch((err) =>{
                setApiKey({...apiKey, erroreMsg : err.response.data.error} );
                setOpenErr(true);
                //console.log(err.response.data.error);
            });
        }


    }

    const GotoView = (event) => {
        navigate("/logview");

    }
    const Reload = (event) => {
        axios.get('https://log.apserial.it/user/apikey', {headers : {'Authorization' : `Bearer ${state.token}` }}).then((res) => {
            if(res.status === 200 ) {
                dispatch(apiKeyUserActionGet(res.data.value));
            }
            else {
                //console.log("ERROR");
            }
        }).catch((err) =>{
            //console.log(err);
        })
    }


    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Grid container  rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={12} item>
                        <Card>
                            <CardHeader title={`Token Manage`}  />
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    Action
                                </Typography>
                                <Button variant="contained" color="success" endIcon={<AddCircleIcon />} size="large" sx={{ m: 2 }} onClick={ addSubmit}>
                                    Add
                                </Button>
                                <Button variant="contained" color="error" endIcon={<DeleteIcon />} size="large" sx={{ m: 2 }} onClick={ delSubmit}>
                                    Delete
                                </Button>
                                <Button variant="contained" endIcon={<ViewTimelineIcon />} size="large" sx={{ m: 2 }} onClick={GotoView}>
                                    View Log
                                </Button>
                                <Button variant="contained" color="info" endIcon={<RefreshIcon />} size="large" sx={{ m: 2 }} onClick={Reload} >
                                    Refresh
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} item>
                        <ApiKeys />
                    </Grid>
                </Grid>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add ApiKey</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Set Name for Your New ApiKey
                    </DialogContentText>
                    <TextField
                        onChange={(event) => {
                            const value = event.target.value;
                            setApiKey({...apiKey, apiKeyName : value});
                        }}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="ApiKey Name"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={ConfirmAdd}>Create</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDel} onClose={handleDelClose}>
                <DialogTitle>Delete ApiKey</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select ApiKey for Delete
                    </DialogContentText>
                    <FormControl sx={{ m: 5, minWidth: 300 }}>
                        <InputLabel id="select-autowidth-label">Select Token</InputLabel>
                        <Select
                            labelId="select-autowidth-label"
                            id="select-autowidth"
                            value={token}
                            onChange={handleChange}
                            //autoWidth
                            label="Token"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                state.securityAccesses &&
                                state.securityAccesses.map((row) => (
                                <MenuItem
                                    key={row.id}
                                    value={row.id}>
                                    {row.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelClose}>Cancel</Button>
                    <Button onClick={ConfirmDel} color="error" >Delete</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openErr}
                onClose={handleErrClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ERROR
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        { apiKey.erroreMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleErrClose} autoFocus>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}
