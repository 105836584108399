import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import {useDispatch} from "react-redux";
import { loginUserAction} from "../store/user/user.actions";
import {useNavigate} from "react-router";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useState} from "react";




function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://log.apserial.it/">
                Pellizzaro Development Projects
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary : {
            main : '#198754'
        },
        background: {
            default : '#212529' //#198754 //#212529
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            '"Montserrat"',
            '"JetBrains Mono"',
            'monospace',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    }
});


const theme = darkTheme;

export default function ResetPasswordRequest() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [err, setErr] = useState('Your Data is incorrect');
    const [statError, setStatError] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setStatError(false);
        setOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const token = new URLSearchParams(window.location.search).get(
            'token'
        );

        if(!token) {
            setErr('you did not have a token for Request Valid!');
            setStatError(true);
            handleClickOpen();
        }
        else if(!data.has('password') || data.get('password') === '' || data.get('password') === null) {
            setErr('you did not insert the password value!');
            setStatError(true);
            handleClickOpen();
        }
        else if(!data.has('NewPassword') || data.get('NewPassword') === '' || data.get('NewPassword') === null) {
            setErr('you did not insert the password value!');
            setStatError(true);
            handleClickOpen();
        }
        else if(data.get('password') !== data.get('NewPassword') ){
            setErr('the value is different!');
            setStatError(true);
            handleClickOpen();
        }
        else {
            let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            let testPassword =  strongRegex.test(data.get('password'));

            if(!testPassword) {
                setErr('Attention the password must contain at least 1 lowercase alphabetic character, at least 1 uppercase alphabetic character, at least 1 numeric character, at least one special character [! @ # $% ^ & *] E must be at least 8 characters long');
                setStatError(true);
                handleClickOpen();
            }
            else {
                if(!statError){
                    axios.post('https://log.apserial.it/user/change', { 'value' :  data.get('password') }, {headers : {'Authorization' : `Bearer ${token}` }}).then((res) => {
                        if(res.status === 201 ) {
                            navigate("/login");

                        }
                        else {
                            setErr(err.response.data.error);
                            handleClickOpen();
                        }


                    }).catch((err) =>{
                        setErr(err.response.data.error);
                        handleClickOpen();
                    })
                }
            }



        }



    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset Password
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoFocus
                            //autoComplete="current-password"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="NewPassword"
                            label="Confirm Password"
                            type="password"
                            id="NewPassword"
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Send Request
                        </Button>
                        <Grid container>
                            <Grid item>
                                <Link href="https://app.apserial.it/register" variant="body2">
                                    {"Don't have an account? Go to Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        ERROR
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            { err}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} autoFocus>Close</Button>
                    </DialogActions>
                </Dialog>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
}
